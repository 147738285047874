import React, { useState, FormEvent, useEffect, useCallback } from 'react'

import { Button, Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, Input, Label, Navbar, Row, Spinner }
    from "reactstrap"
import { useGetUserQuery, useLoginMutation } from '../../backoffice/apis/authenticationAPI';
import { UserLogin } from '../../backoffice/dtos/authentication/userLogin';
import {PasswordField} from "../views/common/PasswordField";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { supportedLanguages } from '../../i18n/i18nProps';
import ReactCountryFlag from 'react-country-flag';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import i18next from 'i18next';
import { toast } from 'react-toastify';
import { setUser } from '../../helpers/localStorage';
import { debug } from 'console';
import { fetchUser, unwrapDataError } from '../../backoffice/apis/axios';
import { AxiosError } from 'axios';


export default function Login() {
    const navigate = useNavigate()
    const {t} = useTranslation(['login', 'common'])

    const [formState, setFormState] = useState<UserLogin>({ userName: '', password: '', rememberMe: false })
    const [globeOpen, setGlobeOpen] = useState(false);

    const [loginMutation] = useLoginMutation()
    const [ isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState<string | null>()

    const [busy, setBusy] = useState<boolean>(false)

    useEffect(() =>{
        const controller = new AbortController()
        fetchUser({signal: controller.signal})
            .then(user => {
                setUser(user.data)
            })
            .catch(error => {
                if ((error as AxiosError).status != 401 && (error as AxiosError).status != undefined)
                    setError(unwrapDataError(error))
            })
            .finally(() => setIsLoading(false))
        return () => controller.abort()
    }, [])

    const handleChange = ({
        target: { name, value, checked },
    }: React.ChangeEvent<HTMLInputElement>) => {
        setError(null)
        if (typeof((formState as any)[name]) === "boolean"){
            setFormState((prev) => ({...prev, [name]: checked}))
        } else {
            setFormState((prev) => ({...prev, [name]: value}))
        }
    }
    // const errorMsg = isError ? <div className="text-danger">{t('connection_error')}</div> :
    //     loginResult && loginResult.error ? <div className="text-danger">{loginResult.error}</div> : <></>

    const handleLogin = useCallback(async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        if (busy)
            return
            
        if (formState.userName && formState.password) {
            try {
                setBusy(true)
                const result = await loginMutation(formState).unwrap();
                setUser(result)
            } catch(error: any) {
                setError(error.data ? error.data : error.error)
            } finally {
                setBusy(false)
            }
        }
    }, [busy, formState])

    let page: JSX.Element
    if (isLoading){
        page = (
            <div className='d-flex vh-100 align-items-center justify-content-center'>
                <Spinner/>
            </div>
        )
    } else {
        page = (
            <>
            <div className='bg-login-lvl-3 d-flex flex-column vh-100'>
                <div className="bg-login-lvl-3 d-flex justify-content-end">
                    <div className='p-2'>
                        <Dropdown nav className='list-unstyled' isOpen={globeOpen} toggle={() => setGlobeOpen(!globeOpen)}>
                            <DropdownToggle>
                                <FontAwesomeIcon icon={faGlobe} />
                            </DropdownToggle>
                            <DropdownMenu>
                                {supportedLanguages.map((item, index) => {
                                    return (
                                        <DropdownItem key={index} className={i18next.language === item.code ? 'bold' : ''} onClick={() => i18next.changeLanguage(item.code)}>
                                            <ReactCountryFlag
                                                className="emojiFlag"
                                                countryCode={item.countryCode}
                                                style={{
                                                    fontSize: '1.2em',
                                                    lineHeight: '1.2em',
                                                }}
                                                aria-label="United States"
                                            />
                                            <span className='mx-2'>{item.name}</span>
                                        </DropdownItem>
                                    )
                                })}
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </div>
                <div className='d-flex flex-fill align-items-center justify-content-center'>           
                        <Container className='bg-lvl-3 rounded-3 shadow-sm p-5' style={{maxWidth:"512px",minHeight:"400px",alignItems:'center',justifyContent:'center'}} >
                            <div>
                                <img src={process.env.REACT_APP_LOGO} className="w-100 h-100 mb-4" />
                                <Form onSubmit={handleLogin}>
                                    <Label tag="div" for="userName">{t('username')}</Label>
                                    <Input type="text" name='userName' value={formState.userName} onChange={handleChange} />
                                    <div style={{height:"15px"}}/>
            
                                    <Label tag="div" for="description">{t('password')}</Label>
                                    <PasswordField name='password' value={formState.password} onChange={handleChange} />
                                    <div style={{height:"15px"}}/>
                                    <div>
                                        <Input type="checkbox" name="rememberMe" checked={formState.rememberMe} onChange={handleChange}/>
                                        <Label for="rememberMe">{"\u00A0"}Remember Me</Label>
                                    </div>
                                    <br/>
                                    {error && <div style={{color: "red"}}>{error}</div>}
                                    <Row className='gy-3'>
                                        <Col sm={5}>
                                            <Button className='w-100' outline onClick={() => navigate("/reset/password/request")} disabled={busy}>{t('reset_button_text')}</Button>
                                        </Col>
                                        <Col sm={7}>
                                            <Button className="w-100" type="submit" color="primary" disabled={busy}>
                                                { busy && <Spinner size="sm"/>} {t('login_button_text')}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </Container>
                    </div>
                </div>
            </>
        )
    }

    return page

}
