import 'bootstrap/dist/css/bootstrap.min.css';
import "react-datepicker/dist/react-datepicker.css";

import React, {useCallback, useMemo, useState } from 'react';

import DatePicker from "react-datepicker";

import {Button, ButtonGroup, Col, Form, Input, InputGroup, InputGroupText, Label, Row} from "reactstrap"
import { IconDefinition,  faEuroSign, faSave, faTrash, faUser, faUserCheck, faUserXmark, faXmark, faCheck, faX} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import { confirmAlert } from 'react-confirm-alert'
import {ApprovalStatusColor, EApprovalStatus, getApprovalStatusDescription, getApprovalStatusSymbol} from '../../enums/EApprovalStatus'
import { toast } from 'react-toastify';
import { useApprovePromotionMutation, useDeletePromotionMutation, useRejectPromotionMutation, useUpdatePromotionMutation } from '../../backoffice/apis/promotionAPI';
import { Promotion } from '../../backoffice/dtos/product/promotion';
import { getCanReject as getCanReject, getCanEdit, getCanPromote, user } from '../../backoffice/dtos/authentication/user'
import IdItemSelection from "../views/common/IdItemSelection";
import {EMediaType, MediaTypeDescription, MediaTypeOptions} from "../../enums/EMediaType";
import { useTranslation } from 'react-i18next';

export default function PromotionHeaderForm({
    user,
    promotion: initialPromotion,
    isLoading = false,
    hidePromotionControls = false,
    toggle
}: {
    user: user | undefined,
    promotion: Promotion,
    isLoading?: boolean,
    hidePromotionControls ?: boolean,
    toggle: () => void
}) {
    
    const [formState, setFormState] = useState(initialPromotion)
    const [approvalDescription, setApprovalDescription] = useState('')
    const [showSaveButton, setShowSaveButton] = useState(false)
    const [approvePromotion] = useApprovePromotionMutation()
    const [rejectPromotion] = useRejectPromotionMutation()
    const {t} = useTranslation(['promotion_timeline', 'common','promotion'])

    const [updatePromotion] = useUpdatePromotionMutation()
//  const [deletePromotion] = useDeletePromotionMutation()

    const canEdit = user ? getCanEdit(user) : false;

    const canApprove = useMemo(() =>
        user
            && getCanPromote(user, initialPromotion), [user])

    const formChangeHandler = ({
        target: { name, value },
    }: React.ChangeEvent<HTMLInputElement>) => {
        if (name === 'approvalDescription') {
            setApprovalDescription(value)
        } else {
            if (canEdit) {
                setFormState((prev) => ({ ...prev, [name]: value }))
                setShowSaveButton(true)
            }
        }
    }

    const saveHandler = async () => {
        try {
            await updatePromotion({ ...formState }).unwrap()
            setShowSaveButton(false)
        } catch (e: any) {
            toast(t('unsuccessful_save_text',{ns:'promotion', error: e}))
        } finally {
            // setIsEditing(false)
        }
    }

    const onFromDateChange = (fieldName: string) => {
        return (date: Date) => {
            if (canEdit) {
                const localedDate = new Date(date.getTime() +  (-date.getTimezoneOffset()) *60000);
                setFormState((prev) => ({ ...prev, [fieldName]: localedDate.toISOString() }))
                setShowSaveButton(true)
            }
        }
    }

    const approvePromotionHandler = () => {
        confirmAlert({
            title: t('approve_confirm_alert_title',{ns:'promotion'}),
            message: t('approve_confirm_alert_description',{ns:'promotion'}),
            buttons: [
                {
                    label: t('yes',{ns:'common'}), onClick: async () => {
                        // approvePromotion({ id: formState.id, description: approvalDescription })
                        try {
                            await approvePromotion({ id: formState.id, description: approvalDescription }).unwrap()
                        } catch (e: any) {
                            toast(t('unsuccessful_approve_text',{ns:'promotion', error: e}))
                        }
                    }
                },
                { label: t('no',{ns:'common'}), onClick: () => { } }]
        });
    }

    const approveButton = canApprove && !hidePromotionControls 
        ? <Button color="success" onClick={approvePromotionHandler} title={t('approve',{ns:'common'})}><FontAwesomeIcon icon={faUserCheck} /></Button> 
        : <></>

    const canReject = user && getCanReject(user, initialPromotion)
        

    let deleteRejectText: string;
    let deleteRejectMessage: string;
    let rejectButtonIcon: IconDefinition

    if (formState.approvalStatus === EApprovalStatus.Draft || formState.approvalStatus === EApprovalStatus.Rejected || formState.approvalStatus >= EApprovalStatus.POSubmitted) {
        deleteRejectText = t('cancel',{ns:'common'})
        deleteRejectMessage = t('reject_on_delete_confirm_alert_description',{ns:'promotion'})
        rejectButtonIcon = faXmark
    } else {
        deleteRejectText = t('reject',{ns:'common'});
        deleteRejectMessage = t('reject_confirm_alert_description',{ns:'promotion'})
        rejectButtonIcon = faUserXmark
    }

    const rejectPromotionHandler = () => {
        confirmAlert({
            title: `${deleteRejectText} `,
            message: deleteRejectMessage,
            buttons: [
                { label: t('yes',{ns:'common'}), onClick: () => rejectPromotion({ id: formState.id, description: approvalDescription }) },
                { label: t('no',{ns:'common'}), onClick: () => { } }]
        });
    }

    const rejectButton = canReject && !hidePromotionControls ? 
        <Button color="danger" onClick={rejectPromotionHandler} title={deleteRejectText}><FontAwesomeIcon icon={rejectButtonIcon} /></Button> : <></>

    const approverElement = formState.approver ? 
    (
        <InputGroup>
            <InputGroupText>
                <FontAwesomeIcon icon={faUser}/>
            </InputGroupText>
            <Input type="text" value={formState.approverName} disabled={true} />
        </InputGroup> 
    ): (
        <></>
    )
    const disableSave = !canEdit || !showSaveButton || isLoading;

    const statusIcon = useMemo(() => getApprovalStatusSymbol(formState.approvalStatus), [formState.approvalStatus])

    const totalProductCost = useMemo(() =>{
        let products = initialPromotion.promotionProducts
        
        let totalEstCost: number = 0 
        products.forEach(product => {
            if (product.sellOutEuro > 0){
                totalEstCost += product.sellOutEuro  
            }else{
                totalEstCost += product.euroEstimate
            }
        });
        
        return totalEstCost
    }, [initialPromotion.promotionProducts])

    const total = useMemo(() => totalProductCost + initialPromotion.mediaFee,[initialPromotion.mediaFee])

    const toggleProposalSent = useCallback((e: any) => {
        e.preventDefault()
        if (canEdit) {
            setShowSaveButton(true);
            setFormState(prev => ({...prev, proposalSent: !prev.proposalSent}))
        }
    }, [formState])

    const checkboxProposal = useMemo(() => {
        if (formState.proposalSent) {
            return {
                icon: faCheck,
                color: "success"
            }
        } else {
            return {
                icon: faXmark,
                color: "danger"
            }
        }
    }, [formState.proposalSent]);

    return (
        <>
        <Form>
            <Row className="gy-2 p-2">
                <Label for="approvalStatus" lg={2}>{t('status')}</Label>

                <Col lg={4}>
                    <InputGroup>
                        {statusIcon &&
                            <InputGroupText style={{backgroundColor: ApprovalStatusColor[formState.approvalStatus as EApprovalStatus], color: "white"}}>
                                <FontAwesomeIcon icon={statusIcon}/>
                            </InputGroupText>
                        }
                        <Input type="text" name='approvalStatus' value={getApprovalStatusDescription(formState.approvalStatus as EApprovalStatus)} disabled={true} />
                    </InputGroup>
                </Col>
                <Col lg={4}>
                    {approverElement}
                </Col>
                { !hidePromotionControls && (canApprove || canReject) &&
                    <Col lg={2}>
                        <ButtonGroup className="w-100">
                            {approveButton}
                            {rejectButton}
                        </ButtonGroup>
                    </Col>
                }
            </Row>

            <Row className="gy-2 p-2">

                <Label for="Identification" lg={2}>{t('identifiers')}</Label>
                <Col lg={4}>
                    <InputGroup>
                        <InputGroupText color="primary">TPM</InputGroupText>
                        <Input className="text-align-end" type="text" disabled value={formState.id}/>
                    </InputGroup>
                </Col>
                { formState.poNumber &&
                    <Col lg={5}>
                        <InputGroup>
                            <InputGroupText color="primary">SAP</InputGroupText>
                            <Input className="text-align-end" type="text" disabled value={formState.poNumber}/>
                        </InputGroup>
                    </Col>
                }
            </Row>

            <Row className="gy-2 p-2">
                <Label for="customerDescription" lg={2}>{t('brand', {ns:"common"})}</Label>
                <Col lg={10}>
                    <Input type="text" name='customerDescription' value={formState.customerDescription} disabled={true} />
                </Col>
            </Row>

            <Row className="gy-2 p-2">
                <Label for="description" lg={2}>{t('description')}</Label>
                <Col lg={10}>
                    <Input type="text" name='description' onChange={formChangeHandler} value={formState.description} disabled={!canEdit} />
                </Col>
            </Row>
            <Row className="gy-2 p-2">
                <Label for="mediaFee" lg={2}>Media Type</Label>
                <Col lg={10}>
                    <IdItemSelection id={formState.mediaTypeId} disabled={!canEdit} options={MediaTypeOptions} setSelection={(id) => {
                        setShowSaveButton(true);
                        setFormState((prev) => {
                            return { ...prev, mediaTypeId: (id ?? 0), mediaTypeDescription: (id ? MediaTypeDescription[id as EMediaType] : "") };
                        });
                    }}/>
                </Col>
                
            </Row>
            <Row className='gy-2 p-2'>          
                <Col lg={2} className='d-flex flex-column justify-content-center'>
                    <Label for="estTotals"> {t('totals')}</Label>
                </Col>
                <Col lg={3}>
                    <InputGroup>                    
                        <div className="form-floating">
                            <Input type="text" className="text-align-end" name='mediaFee' onChange={formChangeHandler} value={formState.mediaFee} disabled={!canEdit}/>
                            <Label for="floatingInput">Media Fee</Label>
                        </div>
                        <InputGroupText><FontAwesomeIcon icon={faEuroSign} /></InputGroupText>  
                    </InputGroup>
                </Col>
                <Col lg={4}>
                <InputGroup>
                    <div className="form-floating">
                        <Input type='text' className="text-align-end" name='totalEst' value={totalProductCost.toFixed(2)} disabled={true}/>
                        <Label for="floatingInput">{t('total_value_products')}</Label>
                    </div>
                    <InputGroupText><FontAwesomeIcon icon={faEuroSign} /></InputGroupText>                    
                </InputGroup>
                </Col>
                
                <Col lg={3}>
                    <InputGroup>
                        <div className="form-floating">
                            <Input type='text' className="text-align-end" name='total' value={total.toFixed(2)} disabled={true}/>   
                            <Label for="floatingInput">{t('total')}</Label> 
                        </div>                   
                        <InputGroupText><FontAwesomeIcon icon={faEuroSign} /></InputGroupText>                           
                    </InputGroup>                
                </Col>                
            </Row>
            
            <Row className="gy-2 p-2">
                <Label for="fromDate" lg={2}>{t('duration',{ns:'common'})}</Label>
                <Col lg={5}>
                    <InputGroup>
                        <InputGroupText>{t('start',{ns:'common'})}</InputGroupText>
                        <DatePicker className='form-control' dateFormat="dd-MM-yyyy"
                            selected={new Date(formState.fromDate)}
                            onChange={onFromDateChange('fromDate')}
                            disabled={!canEdit} />
                    </InputGroup>
                </Col>
                <Col lg={5}>
                    <InputGroup>
                        <InputGroupText>{t('end',{ns:'common'})}</InputGroupText>
                        <DatePicker className='form-control flex-fill' dateFormat="dd-MM-yyyy"
                            selected={new Date(formState.toDate)}
                            onChange={onFromDateChange('toDate')}
                            disabled={!canEdit}/>
                    </InputGroup>
                </Col>
            </Row>       
            <Row className="gy-2 p-2">
                <Label lg={2}>{t("external", {ns: "promotion"})}</Label>
                <Col lg={3}>
                    <ButtonGroup className="unselectable cursor-pointer" onClick={toggleProposalSent}>
                        <Button enabled={canEdit} color={checkboxProposal.color}><FontAwesomeIcon icon={checkboxProposal.icon}/></Button>
                        <InputGroupText>{t("proposal_sent")}</InputGroupText>
                    </ButtonGroup>
                </Col>
            </Row>

            { canEdit && showSaveButton &&
                <Row className='gy-2 p-2'>
                    <Col lg={10}/>
                    <Col lg={2}>
                        <Button className='w-100' color={disableSave ? "secondary" : "success"} disabled={disableSave} onClick={saveHandler}><FontAwesomeIcon icon={faSave}/> {t('Save')}</Button>
                    </Col>
                </Row>
            }
        </Form>
            
        </>
    )

}
