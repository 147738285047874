import { baseApi } from "./baseAPI"
import { Brand } from "../dtos/product/Brand";

export const brandsAPI = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getBrands: build.query<Brand[], void>({
            query: () => "brand"
            ,
            // Cache for id
            providesTags: (_result, _error, brand) => [{ type: 'Brand', brand}],
            // transformResponse: (rawResult: Promotion, meta) => fixDates(rawResult)
        })
    }),
    overrideExisting: false,
})

export const {
    useGetBrandsQuery
} = brandsAPI
