export function dateOnly(date: Date | string): Date {
    // eslint-disable-next-line valid-typeof
    if (date instanceof Date) {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate())
    } else {
        try {
            const dateValue = new Date(date)
            return new Date(dateValue.getFullYear(), dateValue.getMonth(), dateValue.getDate())
        } catch (e) {
            return new Date()
        }
    }
}

export function formatToYMD(date: Date, delimeter = '-'): string {
    return `${date.getFullYear()}${delimeter}${String(date.getMonth() + 1).padStart(2, '0')}${delimeter}${String(date.getDate()).padStart(2, '0')}`
}

export function formatToDMY(date: Date, delimeter = '-'): string {
    return `${String(date.getDate()).padStart(2, '0')}${delimeter}${String(date.getMonth() + 1).padStart(2, '0')}${delimeter}${date.getFullYear()}`
}

export function getMonthDuration(startDate: Date, endDate: Date): number {
    const fromDateMonths = startDate.getMonth();
    const toDateMonths = (endDate.getFullYear() - startDate.getFullYear()) * 12 + endDate.getMonth();

    return toDateMonths - fromDateMonths;
}

export function getDayDuration(startDate: Date, endDate: Date, days: number): { years: number, months: number, days: number }{
    const initialDate = {
        years: endDate.getFullYear() - startDate.getFullYear(),
        months: endDate.getMonth() - startDate.getMonth(),
        days: endDate.getDate() - startDate.getDate() - days
    }

    return initialDate;
}

export const today = new Date();
