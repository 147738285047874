import { Input } from "reactstrap";
import { normalizedNumber } from "../../../../helpers/utils";
import {TimelineMasterWithEvent} from "../../models/NTimelineModel";

export default function InnerContent(
        { id, event }: { id: string, event: TimelineMasterWithEvent }
) {
    const length = event.event.timelineEventDetails.length;
    const showDescription = event.event.description && event.event.description.trim().length > 0;
    const showProducts = length > 0;
    const showTotal = event.event.totalEuro > 0;
    const mediaFee = event.event.mediaFee;
    console.log(`Mediafee ${mediaFee}`)
    const checkboxElement = <><Input readOnly type="checkbox" checked={event.event.proposalSent}/>{' '}</>;
    return <div id={id} style={{paddingLeft: "5px", paddingRight: "5px"}}>
        {
            showDescription &&
            <div>
                {checkboxElement}
                {event.event.description}
            </div>
        }
        {
            showDescription &&
            <br/>
        }
        {
            showProducts &&
            <div>
                {!showDescription && checkboxElement}
                {
                    Array.from(event.event.timelineEventDetails)
                            .sort(m => m.order)
                            .map(m => `${m.description} ${m.discount} ${normalizedNumber(m.hls, 0)}Hls`)
                            .reduce((acc, m) => `${acc}, ${m}`)
                }
            </div>
        }
        <div>
            {!showDescription && !showProducts && checkboxElement}
            <strong>{event.event.id}</strong>
            {event.event.poNumber ? <>({event.event.poNumber})</>:<></>}
            { showTotal &&
                <> <strong>Total:</strong> {normalizedNumber(event.event.totalEuro, 2)}€</>
            }
            { mediaFee > 0 && 
                <>{' '}<strong>Mediafee:</strong> {normalizedNumber(mediaFee, 2)}€</>
            }
        </div>
    </div>
}
