import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker, Range, RangeKeyDict } from 'react-date-range';
import {useCallback, useEffect, useMemo, useState} from "react"
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalHeader} from "reactstrap"
import { SelectOptionAlt } from "../../../helpers/SelectOption"
import {PromotionCriteria} from "../../../backoffice/dtos/product/promotionCriteria";
import { useTranslation } from "react-i18next";
import { getApprovalStatusOptions } from "../../../enums/EApprovalStatus";
import { useGetCustomersQuery } from "../../../backoffice/apis/customerAPI";
import { useGetMediaTypesQuery } from "../../../backoffice/apis/mediaApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faFilterCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { getMessageFromRtkError } from "../../../backoffice/apis/baseAPI";
import { Accordion } from "../../views/common/Accordion/Accordion";
import { AccordionItem } from "../../views/common/Accordion/AccordionItem";
import { getDateRangeThisMonth, getDefaultTimelineRange, getLYRange, getH1Range, getH2Range, getQ1Range, getQ2Range, getQ3Range, getQ4Range, rangeKeyDictToStrDateRange, strDateRangeEquals, strDateRangeToRange } from '../../../helpers/Types';
import { SelectionList } from '../../views/common/SelectionList';
import { shallowEquals } from '../../../helpers/arrayHelpers';
import { useGetProductsQuery } from '../../../backoffice/apis/productAPI';
import { ProductCriteria } from '../../../backoffice/dtos/promotion/productCriteria';
import { useGetBrandsQuery } from '../../../backoffice/apis/brandsAPI';

export default function CriteriaModal(
    {
        isOpen,
        toggle,
        initialFilter,
        currentFilter,
        setSelectedPromotionCriteria,
    }:{
        isOpen: boolean,
        toggle: () => void,
        initialFilter: PromotionCriteria,
        currentFilter: PromotionCriteria,
        setSelectedPromotionCriteria: (criteria : PromotionCriteria) => void
    }
) {
    const {t} = useTranslation(['criteria'])

    const [ promotionCriteria, setPromotionCriteria ] = useState<PromotionCriteria>({...currentFilter})

    const { data: customersData, isFetching: customersAreFetching, error: customersError } = useGetCustomersQuery({ query: "", includeDeleted: false }, { skip: !isOpen })
    const { data: mediaTypes, isFetching: mediaTypesAreFetching, error: mediaTypesError } = useGetMediaTypesQuery(undefined, { skip: !isOpen })
    const { data: products, isFetching: productsAreFetching, error: productsFailed } = useGetProductsQuery({} as Partial<ProductCriteria>, { skip: !isOpen })
    const { data: brands, isFetching: brandsAreFetching, error: brandsFailed } = useGetBrandsQuery(undefined, { skip: !isOpen });

    useEffect(() => {
        setPromotionCriteria(currentFilter);
    }, [currentFilter])

    const customers = useMemo(() => {
        if (!customersData)
            return customersData;

        return [...customersData].sort((a, b) => a.order - b.order)
    }, [customersData]);

    const dateRange: Range = useMemo(() => {
        return strDateRangeToRange(promotionCriteria.dateRange)
    }, [promotionCriteria.dateRange]);

    const setDateRangeChange = useCallback((item: RangeKeyDict) => {
        const date = rangeKeyDictToStrDateRange(item);

        if (date)
            setPromotionCriteria(prev => ({...prev, dateRange: date}));
    }, [])

    return <Modal isOpen={isOpen} toggle={toggle} size='lg'>
        <ModalHeader toggle={toggle}>
            <FontAwesomeIcon icon={faFilter}/>{" "}
            {t("criteria")}
        </ModalHeader>
        <ModalBody>
            <Accordion>
                <AccordionItem className='justify-content-center' id={"daterange"} title={t("daterange")} headerButtonIcon={faFilterCircleXmark} 
                setHeaderButtonClick={
                    strDateRangeEquals(promotionCriteria.dateRange, initialFilter.dateRange)
                    ? undefined
                    : () => {
                        setPromotionCriteria(prev => ({...prev, dateRange: initialFilter.dateRange}))
                    }
                }>
                    <div className='d-flex justify-content-center mt-1 mb-1'>
                        <DateRangePicker 
                            onChange={setDateRangeChange} 
                            ranges={[dateRange]}
                            staticRanges={[
                                {
                                label: 'This Year',
                                range: () => ({
                                    startDate: getDefaultTimelineRange().fromDate,
                                    endDate: getDefaultTimelineRange().toDate
                                }),
                                isSelected() {
                                    return false;
                                }
                                },
                                {
                                    label: 'LY',
                                    range: () => ({
                                    startDate: getLYRange().fromDate,
                                    endDate: getLYRange().toDate
                                    }),
                                    isSelected() {
                                    return false;
                                    }
                                },
                                {
                                    label: 'H1',
                                    range: () => ({
                                    startDate: getH1Range().fromDate,
                                    endDate: getH1Range().toDate
                                    }),
                                    isSelected() {
                                    return false;
                                    }
                                },
                                {
                                    label: 'H2',
                                    range: () => ({
                                    startDate: getH2Range().fromDate,
                                    endDate: getH2Range().toDate
                                    }),
                                    isSelected() {
                                    return false;
                                    }
                                },
                                {
                                    label: 'Q1',
                                    range: () => ({
                                    startDate: getQ1Range().fromDate,
                                    endDate: getQ1Range().toDate
                                    }),
                                    isSelected() {
                                    return false;
                                    }
                                },
                                {
                                    label: 'Q2',
                                    range: () => ({
                                    startDate: getQ2Range().fromDate,
                                    endDate: getQ2Range().toDate
                                    }),
                                    isSelected() {
                                    return false;
                                    }
                                },
                                {
                                    label: 'Q3',
                                    range: () => ({
                                    startDate: getQ3Range().fromDate,
                                    endDate: getQ3Range().toDate
                                    }),
                                    isSelected() {
                                    return false;
                                    }
                                },
                                {
                                    label: 'Q4',
                                    range: () => ({
                                    startDate: getQ4Range().fromDate,
                                    endDate: getQ4Range().toDate
                                    }),
                                    isSelected() {
                                    return false;
                                    }
                                },
                                {
                                    label: 'This Month',
                                    range: () => ({
                                    startDate: getDateRangeThisMonth().fromDate,
                                    endDate: getDateRangeThisMonth().toDate
                                    }),
                                    isSelected() {
                                    return false;
                                    }
                                }
                            ]}
                        />
                    </div>
                </AccordionItem>
                <AccordionItem className='justify-content-center' id={"brands"} title={t("brands")} headerButtonIcon={faFilterCircleXmark}
                    setHeaderButtonClick={
                        shallowEquals(promotionCriteria.brands, initialFilter.brands)
                        ? undefined
                        : () => {
                            setPromotionCriteria(prev => ({...prev, brands: initialFilter.brands}))
                        }
                    }>
                    <SelectionList 
                        items={brands?.map(m => { 
                            return {
                                value: m.code,
                                label: m.description
                            }
                        })}
                        selection={promotionCriteria.brands}
                        error={brandsFailed && getMessageFromRtkError(brandsFailed)} 
                        isFetching={brandsAreFetching} 
                        onChange={function (option: SelectOptionAlt[] | undefined): void {
                            if (!option || option.length === 0){
                                setPromotionCriteria(prev => ({...prev, brands: undefined}))
                                return;
                            }
                            const brands = option.map(m => { return m.value as string; });
                            setPromotionCriteria(prev => ({...prev, brands}));
                        }} 
                    /> 
                </AccordionItem>
                <AccordionItem className='justify-content-center' id={"products"} title={t("products")} headerButtonIcon={faFilterCircleXmark}
                    setHeaderButtonClick={
                       shallowEquals(promotionCriteria.products, initialFilter.products)
                       ? undefined
                       : () => {
                           setPromotionCriteria(prev => ({...prev, products: initialFilter.products}))
                       }
                    }>
                    <SelectionList 
                        items={products?.map(m => { 
                            let productDescription = ['', m.description, `[${parseInt(m.code).toFixed(0)}]`];
                            if (m.shortDescription && m.shortDescription.trim().length > 0)
                            {
                                productDescription[0] = `(${m.shortDescription})`;
                            }

                            return {
                                value: m.code,
                                label: productDescription.join(' ')
                            }
                        })}
                        selection={promotionCriteria.products}
                        error={productsFailed && getMessageFromRtkError(productsFailed)} 
                        isFetching={productsAreFetching} 
                        onChange={function (option: SelectOptionAlt[] | undefined): void {
                            if (!option || option.length === 0){
                                setPromotionCriteria(prev => ({...prev, products: undefined}))
                                return;
                            }
                            const products = option.map(m => { return m.value as string; });
                            setPromotionCriteria(prev => ({...prev, products}));
                        }} 
                    /> 
                </AccordionItem>
                <AccordionItem id={"customerIds"} title={t("customers")} headerButtonIcon={faFilterCircleXmark} 
                setHeaderButtonClick={
                    shallowEquals(promotionCriteria.customerIds, initialFilter.customerIds)
                    ? undefined
                    : () => {
                        setPromotionCriteria(prev => ({...prev, customerIds: initialFilter.customerIds}))
                    } 
                }>
                    <SelectionList 
                        items={customers?.map(m => ({ 
                            value: m.id,
                            label: `(${m.shortDescription}) ${m.description}`
                        }))}
                        selection={promotionCriteria.customerIds}
                        error={customersError && getMessageFromRtkError(customersError)} 
                        isFetching={customersAreFetching} 
                        onChange={function (option: SelectOptionAlt[] | undefined): void {
                            if (!option || option.length === 0){
                                setPromotionCriteria(prev => ({...prev, customerIds: undefined}))
                                return;
                            }
                            const customers = option.map(m => m.value as number);
                            setPromotionCriteria(prev => ({...prev, customerIds: customers}));
                        }} 
                    /> 
                </AccordionItem>
                <AccordionItem id={"mediaTypeIds"} title={t("mediaTypes")} headerButtonIcon={faFilterCircleXmark}
                setHeaderButtonClick={
                    shallowEquals(promotionCriteria.mediaTypeIds, initialFilter.mediaTypeIds)
                    ? undefined
                    : () => {
                        setPromotionCriteria(prev => ({...prev, mediaTypeIds: initialFilter.mediaTypeIds}))
                    }
                }>
                    <SelectionList 
                        items={mediaTypes?.map(m => ({ 
                            value: m.id,
                            label: m.description
                        }))} 
                        selection={promotionCriteria.mediaTypeIds}
                        error={mediaTypesError && getMessageFromRtkError(mediaTypesError)} 
                        isFetching={mediaTypesAreFetching} 
                        onChange={function (option: SelectOptionAlt[] | undefined): void {
                            if (!option || option.length === 0){
                                setPromotionCriteria(prev => ({...prev, mediaTypeIds: undefined}))
                                return;
                            }
                            const mediaTypes = option.map(m => m.value as number);
                            setPromotionCriteria(prev => ({...prev, mediaTypeIds: mediaTypes}));
                        }} 
                    />
                </AccordionItem>
                <AccordionItem id={"approvalStatus"} title={t("approvalStatuses")} headerButtonIcon={faFilterCircleXmark}
                setHeaderButtonClick={
                    shallowEquals(promotionCriteria.approvalStatus, initialFilter.approvalStatus)
                    ? undefined
                    : () => {
                        setPromotionCriteria(prev => ({...prev, approvalStatus: initialFilter.approvalStatus}))
                    }
                }>
                    <SelectionList 
                        items={getApprovalStatusOptions()} 
                        error={undefined} 
                        isFetching={false} 
                        selection={promotionCriteria.approvalStatus}
                        onChange={function (option: SelectOptionAlt[] | undefined): void {
                            if (!option || option.length === 0){
                                setPromotionCriteria(prev => ({...prev, approvalStatus: undefined}))
                                return;
                            }
                            const approvalStat = option.map(m => m.value as number);
                            setPromotionCriteria(prev => ({...prev, approvalStatus: approvalStat}));
                        }} 
                    />
                </AccordionItem>
                <AccordionItem id={"baseParams"} title={t("parameters")} headerButtonIcon={faFilterCircleXmark}
                setHeaderButtonClick={
                    promotionCriteria.onlyAvailableToPromote === initialFilter.onlyAvailableToPromote
                        && promotionCriteria.onlyOwned === initialFilter.onlyOwned
                    ? undefined
                    : () => {
                        setPromotionCriteria(prev => ({
                            ...prev,
                            onlyOwned: initialFilter.onlyOwned,
                            onlyAvailableToPromote: initialFilter.onlyAvailableToPromote
                        }))
                    }
                }>
                    <FormGroup switch className="m-1">
                        <Input 
                            type="switch" 
                            role="switch"
                            onChange={(change) => setPromotionCriteria(prev => ({...prev, onlyOwned: change.target.checked }))} 
                            checked={promotionCriteria.onlyOwned === true}
                        />{" "}

                        <Label className="m-0" check>
                            {
                                t("onlyOwned")
                            }
                        </Label>
                    </FormGroup>
                    <FormGroup switch className="m-1">
                        <Input 
                            type="switch" 
                            role="switch"
                            onChange={(change) => setPromotionCriteria(prev => ({...prev, onlyAvailableToPromote: change.target.checked }))} 
                            checked={promotionCriteria.onlyAvailableToPromote === true}
                        />{" "}

                        <Label className="m-0" check>
                            {
                                t("onlyAvailableToPromote")
                            }
                        </Label>
                    </FormGroup>
                </AccordionItem>
            </Accordion>
            <div className='d-flex justify-content-end m-1'>
                <Button className='m-1' color='primary' onClick={() => {
                    setSelectedPromotionCriteria(promotionCriteria)
                    toggle()
                }}>{t('Apply')}</Button>
                <Button className='m-1' onClick={() => toggle()}>{t('Cancel')}</Button>
            </div>
        </ModalBody>
    </Modal>
}
